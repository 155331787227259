import axios from 'axios';

let apiRequest = {}

/* 网络请求模版 opts-参数 apiurl-请求后缀 method-请求方式 */
apiRequest.RequestTemplate = (opts, apiurl, method) => {
    return new Promise((resolve, reject) => {
        let TEMP = {
            url: apiurl,
            params: opts,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ''
            },
            method: method
        }
        axios.request(TEMP).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err);
        })
    })
}

/* 获取监控预览地址 */
apiRequest.queryVideoUrl = function(opts){
    let api = '/api/video/preview?id=' + opts.id + '&protocol=wss'
    return apiRequest.RequestTemplate({}, api, 'GET');
}

export default apiRequest;

