let config = {
	// 域名配置
	apiBaseUrl: '/api',
	gisBaseUrl: '/gis',

	// cesium调试配置
	cesiumOpts:{
		isCesiumModel: true, //开启模型渲染
		isFxaa: true, //开启抗锯齿
		isHD: true, //开启高清渲染
	}
}

export default config;