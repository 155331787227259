import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* 解决重复点击路由报错的BUG */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

/* 参数说明 */
// path：路由路径
// name：组件名称
// meta：扩展项 （isValid：跳转时是否豁免 false不可通行则进入鉴权；isMenu：是否是菜单；exBread：是否依附其他菜单(进入此路由高亮其他菜单)；flag：权限标识；icon：菜单图标；label：菜单名称；bread：路由面包屑）
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index',
      name: 'Index',
      component: () => import('@/views/index/Index'),
      meta: { isValid:true, isMenu:false, flag:'admin@home', icon:'', label:'首页', bread:'首页'},
      children: []
    },
    {
      path: '/index',
      name: 'Index',
      component: () => import('@/views/index/Index'),
      meta: { isValid:true, isMenu:false, flag:'admin@home', icon:'', label:'首页', bread:'首页'},
      children: []
    },
    {
      path: '/neighbor',
      name: 'Neighbor',
      component: () => import('@/views/neighbor/Neighbor'),
      meta: { isValid:true, isMenu:false, flag:'admin@home', icon:'', label:'首页', bread:'首页'},
      children: []
    },
  ]
})

export default router;
