<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  height: 100%;
  width: 100%;
  display: block;
  margin: 0px;
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
  color: #000000;
}
.el-message--warning {
    background-color: #236da0 !important;
    border-color: #4aa9d2 !important;
}
.el-message--success {
    background-color: #236da0 !important;
    border-color: #4aa9d2 !important;
}
.cesium-widget-credits{
  	display:none !important;
}
</style>
