import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

console.log = ()=>{}
console.info = ()=>{}
console.error = ()=>{}
console.warn = ()=>{}

/* moment.js 注册 */
import Moment from 'moment'
Vue.prototype.Moment = Moment

/* util.js 注册 */
import util from './lib/util'
Vue.prototype.$Util = util

/* config.js 注册 */
import config from './lib/config'
Vue.prototype.$Config = config;

/* 滚动插件 */
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

/* element 注册 */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

/* axios 注册 */
import apiRequest from '@/lib/request.js'
Vue.prototype.$apiRequest = apiRequest;

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
